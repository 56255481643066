<template>
                    
                    <div class="row" v-if="isReady">

                      <div class="col-md-4 col-sm-4 col-lg-4 min-pad">

                            <div class="card">

                              <div class="card-body">


                                <div class="dev-wrapper">

                                  <div class="dev-details">


                                    <h3>{{ model.property.code }}</h3>
                                    
                                    <h5>{{ model.property.design.name }}</h5>
                                    <img :src="$thumb(model.property.design.image)"/>
                                    <br/>
                                    <br/>
                                    <h6>Precio: ${{ $numberFormat(model.property.design.price) }}</h6>
                                    <p>Construcción: {{ model.property.design.m2inside }} m2</p>
                                    <p>Terraza: {{ model.property.design.m2outside }} m2</p>
                                    <p>Baños: {{ model.property.design.bathrooms }}</p>

                                  </div>

                    
                                  <br>


                                </div>

                              </div>

                            </div>


                            </div>

                            <div class="col-md-8 col-sm-8 col-lg-8 min-pad">

                                <div class="card">

                                  <div class="card-body">

                                      <div class="">
                                        <span class="float-right">

                                          <a href="javascript:void(0)" class="disabled action-link">
                                            <i class="mdi mdi-email-fast-outline"></i>
                                          </a>
                                        
                                          <a href="javascript:void(0)" class="disabled action-link">
                                            <i class="mdi mdi-download"></i>
                                          </a>
                                        </span>
                                      </div>
                                      <br>
                                      <blockquote class="blockquote">

                                        <div class="form-group">

                                          
                                          
                                                    
                                          <div class="row">

                                            
                                              <div class="col-md-6 col-sm-6 col-lg-6">

                                                <h5>Precio de venta</h5>
                                                {{ $numberFormat(model.sale_price) }}


                                              </div>

                                              <div class="col-md-6 col-sm-6 col-lg-6">
                                                  <div class="prop-payment-selector">
                                                          <h5>Forma de pago</h5>
                                                          <div class="payment-radio form-check"><label for="cash" class="form-check-label">De contado <input disabled type="radio" name="paymentoptions" v-model="model.payment_option" value="cash" class="form-check-input" id="cash" checked/><i class="input-helper"></i></label></div>
                                                          <div class="payment-radio form-check"><label class="form-check-label" for="credit">A plazos <input disabled  type="radio" name="paymentoptions" class="form-check-input" v-model="model.payment_option" value="credit" id="credit"/><i class="input-helper"></i></label></div>
                                                  </div>  

                                              </div>

                                          </div>


                                           


                                        </div>
                                      </blockquote>


                                      <blockquote class="blockquote">
                                        <h5>Apartado</h5>
                                        {{ $numberFormat(model.deposit) }}
                                      </blockquote>

                                      <blockquote class="blockquote">
                                          <h5>Costos adicionales</h5>

                                          <div class="quote-extras">
                                            
                                            <div v-for="(extra,index) in model.extras" :key="index">

                                              <p>{{ extra.name }}</p>
                                                
                                            </div>

                                          </div>
                                          

                                      </blockquote>


                                        
                                        
                                        <blockquote class="blockquote" v-if="isCredit">

                                              

                                                      <div class="credit-option" >
                                                          <div class="form-group">
                                                              <h5>Enganche</h5>
                                                             {{ model.down_payment_percentage }}%
                                                          </div>
                                                      </div>
                                              

                                        </blockquote>


<!--
                                              <blockquote class="blockquote">
                                                  <balance :hide="hideOptions" :total="getTotal" :deposit="getDeposit" :down="calculateDown"/>
                                              </blockquote>
-->





                                              <blockquote class="blockquote" v-if="isCredit">

                                              <div class="row">
                                                  <div class="col-md-6 col-sm-6 col-lg-6">    
                                                      <h5>Plazo</h5>
                                                      {{model.total_payments}}
                                                  </div>

                                                  <div class="col-md-6 col-sm-6 col-lg-6">
                                                      <h5>Fecha de inicio de pago</h5>
                                                     {{ model.payment_date }}
                                                      
                                                  </div>

                                              </div>


                                              </blockquote>




                                              <blockquote class="blockquote" v-if="isCredit">



                                                    <div class="installments">

                                                        <h5>Calendario de pagos</h5>
                                                        <br>

                                                        <div v-for="(payment,index) in model.payments" :key="index">
                                                          {{ payment.amount }}
                                                          
                                                        </div>



                                                    </div>


                                              </blockquote>



                                        <blockquote class="blockquote">

                                          
                                          <h5>Datos del cliente</h5>
                                          <br>
                                          {{ model.client.name }}
                                          
                                          
                                        </blockquote>

                                        
                                      </div>
                                  </div>

                                </div>
                            </div>


                          </template>


<script>


    import api from '@/util/api.js';

    export default{

        name:'viewQuotation',
        routeName:'quotations',
        props:{

            id:{
                required:false

            }

        },

        data(){

            return {

                
                model:{
                  down_payment_percentage:0,
                  down_payment:0,
                  payment_option:'',
                  total_payments:0,
                  deposit:0,
                  sale_price:0,
                    extras:[],
                    payments:[],
                    property:{
                        code:'',
                        
                        design:{
                            name:'',
                            price:''

                        }
                    },
                    client:{
                        name:''

                    }


                }

            }
        },

        computed:{


            isReady(){


                return this.model.property!=undefined?true:false;

            },
            isCredit(){

              return this.model.payment_option=='credit'?true:false;

            }

        },

        methods:{

            getItem(id){


                api.get(this.me(id+'?expand=property,property.design,client,property.design.currency,property.stage,property.stage.parent')).then(r=>{
                    this.fillData(r);
                    

                });


            },
            fillData(r){


              this.fillModel(this.model,r.data);

              const data=r.data;
              const settings=JSON.parse(data.settings);
              
              this.model.extras=JSON.parse(settings.extras);

              this.model.payments=JSON.parese(settings.payments);







            }


        },

        mounted(){

            this.getItem(this.id);


        }



    }

</script>