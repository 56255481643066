<template>
      
  <section class="dashboard">

    <router-link class="rounded-button float-right bg-gradient-primary" :to="link('/developments')">+</router-link>
    <div class="page-header">
      <h3 class="page-title">
        <span class="page-title-icon bg-gradient-primary text-white mr-2">
          <i class="mdi mdi-check"></i>
        </span> {{$options.label }}</h3>
        
<!--
          <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                <a href="" class="action-link"><span></span>Vista general</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                <span></span>Vista general<i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
              </li>
            </ul>
          </nav>
-->                
    </div>
 
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listando {{$options.label}}</h4>
            <div class="table-responsive">


              <table class="table">
                <thead>
                  <tr>
                    <th><input type="checkbox"></th>
                    <th> ID </th>
                    <th> Unidad </th>
                    <th> Modelo </th>
                    <th> Cliente </th>
                    <th> Precio </th>
                    <th> Acciones </th>
                  </tr>
                </thead>
                <tbody>

                  <tr v-if="listing">
                    <td colspan="10">
                      <b-skeleton-table
                        :rows="3"
                        :columns="4"
                        :table-props="{ bordered: true, striped: true }"
                        
                      ></b-skeleton-table>
                    </td>
                  </tr>


                  <tr v-for="(item, index) in items" :key="index">
                    <td><input type="checkbox"></td>
                    <td>{{item.id}}</td>
                    <td>{{ item.property.code }}</td>
                    <td>{{ item.property.design.name }}</td>
                    <td>{{ item.client.name }}</td>
                    <td>{{ $numberFormat(item.sale_price) }}</td>
                    <td>

                      <a class="action-link" @click="viewQuotation(index)"><i class="mdi mdi-eye"></i></a>
                      <a class="action-link" @click="editQuotation(index)"><i class="mdi mdi-square-edit-outline"></i></a>
                      <a class="action-link" @click="deleteMe(item.id,index)"><i class="mdi mdi-delete-outline"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>


    <b-modal ref="quotation_view" size="lg" title="Ver cotización" :hide-footer="true">
        <viewQuotation :id="quotation_id" />
    </b-modal>
    <b-modal ref="quotation_edit" size="lg" title="Ver cotización" :hide-footer="true">
      <formQuotation :property_id="property_id" :property="prop" :sale_price="sale_price" :development_id="development_id"/>
    </b-modal>
  </section>




</template>

<script>

import api from '@/util/api.js';
import viewQuotation from '@/pages/quotations/view';
import formQuotation from '@/pages/quotations/_form';

export default {
  routeName:'quotations',
  label:'Cotizaciones',
  name: 'listQuotations',
  components: {
    viewQuotation,
    formQuotation
  },
  data() {
    return {
      id:0,
      quotation_id:0,
      items:[],
      listing:false,
      sale_price:0,
      development_id:0,
      prop:{}

     
    };
  },


  methods: {

    getItems() {

      this.listing=true;
      
      api.get(this.me('/?expand=property,property.design,client,property.design.currency')).then(response => {
        this.items = response.data;

        
      }).catch(error => {
        console.log(error);
      }).finally(()=>{

        this.listing=false;

      });
    },

    viewQuotation(index){


        this.quotation_id=this.items[index].id;

        this.$refs.quotation_view.show();
        
    },


    editQuotation(index){

      this.development_id=this.items[index].development_id;
      this.quotation_id=this.items[index].id;
      this.property_id=this.items[index].property_id;
      this.prop=this.items[index].property;
      this.sale_price=this.$numberFormat(this.items[index].sale_price);
      this.$refs.quotation_edit.show();

    },

    deleteMe(id,index){





      if(confirm('¿Desea eliminar el elemento?')) {

              api.delete(this.me(id)).then(()=>{

                this.items.splice(index,1);
                this.$forceUpdate();
                
              
              }).catch(error=>{

                console.log(error);
                
              });

        }

      }
          

      
  },

  mounted(){

    
    this.getItems();
  }

}



</script>


